<template>
    <v-row justify="center">
        <v-col>
            <div class="d-flex align-center my-5" style="width: 25px">
                <FormLabel :label="'SHOW'" class="mr-1"/>
                <FormSelectArray 
                :items="['5', '10', '15']"
                :value.sync="paginate"
                :loading="loading"
                class="caption"
                />
            </div>
        </v-col>
        <v-col>
            <div class="d-flex align-center justify-end my-5">
                <v-pagination
                :value="page"
                :length="pageCount"
                :total-visible="5"
                class="f9"
                color="primary"
                flat
                elevation="0"
                @input="$emit('page', $event)"
                >
                </v-pagination>
            </div>
        </v-col>
        
    </v-row>
  </template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        pageCount: {
            type: Number,
            default: 1
        },
        loading: {
            type: Boolean,
            default: false
        },
        paginate: {
            type: String,
            default: '10'
        },
        page: {
            type: Number,
            default: 1
        }
    },
    watch: {
        paginate(val) {
            this.$emit('paginate', val)
        },
        // page(val) {
        //     this.$emit('page', val)
        // }
    }
})
</script>